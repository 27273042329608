import "./App.css";
import React from "react";
import Landing from "./components/Landing/Landing";

function App() {
	return (
		<Landing> </Landing>
	);
}

export default App;
